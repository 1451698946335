import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import votingReducer from './votingMeeting';


const rootReducer = combineReducers({
  voting: votingReducer
});


// Create the persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['meeting', 'voting'], // persist only 'auth' and 'themeDart'
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false
    });
  }
});

// Persist the store
persistStore(store);

// Export the store
export { store };

