import { createSlice } from "@reduxjs/toolkit";

const votingSlice = createSlice({
  name: 'voting',
  initialState: {
    selectedAgendaId: null,
    votingResults: {},
    startVoting: false,
  },
  reducers: {
    setSelectedAgendaId: (state, action) => {
      state.selectedAgendaId = action.payload;
    },
    setVotingResults: (state, action) => {
      const { agendaId, results } = action.payload;
      state.votingResults[agendaId] = results;
    },
    addVotingResult: (state, action) => {
      const { sessionId, userId, voteValue } = action.payload;
      if (!sessionId || !userId) return;
      if (!state.votingResults[sessionId]) {
        state.votingResults[sessionId] = [];
      }
      const existingResult = state.votingResults[sessionId]?.find(result => result?.userId === userId);
      if (existingResult) {
        existingResult.voteValue = voteValue;
      } else {
        state.votingResults[sessionId].push({ userId, voteValue });
      }
    },
    setStartVoting: (state, action) => {
      state.startVoting = action.payload;
    },
  },
});

export const { setSelectedAgendaId, setVotingResults, addVotingResult, setStartVoting } = votingSlice.actions;
export default votingSlice.reducer;
